import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./App.css";
import { v4 as uuidv4 } from "uuid";

function App() {
  const [htmlContent, setHtmlContent] = useState("");
  const ws = useRef(null); // WebSocket referansı

  const fetchHtml = async () => {
    try {
      const response = await axios.post(
        "https://demoapi.magicpay.ai/payment/v1/card-payments/3ds-init",
        {
          conversationId: "1212233",
          externalId: "melisaTest",
          additionalParams: "",
          bankOrderId: uuidv4(),
          buyerMemberId: 1,
          callbackUrl: "https://demoapi.magicpay.ai/payment/v1/success",
          card: {
            cardAlias: "test1",
            cardHolderName: "Kemal Sunal",
            cardNumber: "5549600753928013",
            expireYear: "2027",
            expireMonth: "10",
            cvc: "727",
            cardToken: "test1",
            // cardUserKey: "",
            // storeCardAfterSuccessPayment: true,
          },
          clientIp: "192.168.1.1",
          currency: "EUR",
          installment: 1,
          installment_count: 1,
          fraudParams: {
            deviceId: "device-123",
            location: "US",
            buyerExternalId: "external123",
            buyerPhoneNumber: "1234567890",
            buyerEmail: "buyer@example.com",
          },
          items: [
            {
              externalId: "item123",
              name: "Item1",
              price: 7000,
            },
          ],
          paidPrice: 7000,
          paymentChannel: "Online",
          paymentGroup: "PRODUCT",
          paymentPhase: "AUTH",
          posAlias: "adyen",
          price: 7000,
          recurring: false,
          retry: false,
          walletPrice: 0,
        },
        {
          headers: {
            "x-api-key": "magic-wJW2lERAIYhqSappFEUWH_ZbcJERPFZFYoeNBu1GmIM",
            "x-rnd-key": "key-1",
            "x-auth-version": "V1",
            "x-signature":
              "2f3d0bc165b21859531da62b7665ade2e2021722745bec71e04103d7998fed18",
            "Content-Type": "application/json",
          },
        }
      );
      setHtmlContent(response.data);
    } catch (error) {
      console.error("Error fetching HTML:", error);
    }
  };

  useEffect(() => {
    fetchHtml(); // Initial fetch

    const connectWebSocket = () => {
      ws.current = new WebSocket("wss://demoapi.magicpay.ai/ws");

      ws.current.onopen = () => {
        console.log("WebSocket connection opened");
      };

      ws.current.onmessage = (event) => {
        console.log("Message from server: ", event.data); // Gelen mesajları konsolda görüntüleyin
        fetchHtml(); // Fetch HTML content again on database change
      };

      ws.current.onclose = (event) => {
        console.log("WebSocket connection closed", event);
        // Bağlantı kapandığında tekrar bağlan
        setTimeout(connectWebSocket, 1000);
      };

      ws.current.onerror = (error) => {
        console.error("WebSocket error:", error);
        ws.current.close(); // Hata aldığında WebSocket'i kapat
      };
    };

    connectWebSocket();

    // Cleanup function
    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);

  return (
    <div className="App">
      <h1>RealTime PF Change</h1>
      <br />
      <br />
      <iframe
        title="contentFrame"
        style={{ width: "100%", height: "500px", border: "1px solid #000" }}
        srcDoc={htmlContent}
      ></iframe>
    </div>
  );
}

export default App;
